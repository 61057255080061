@font-face {
  font-family: 'Din Mono';
  src: url('@{icon-font-path}pf_din_mono_medium.woff2') format('woff2'),
  url('@{icon-font-path}pf_din_mono_medium.woff') format('woff'),
  url('@{icon-font-path}pf_din_mono_medium.ttf') format('truetype');
  font-weight: 600;
  font-style: 600;
}

@font-face {
  font-family: 'Din Mono';
  src: url('@{icon-font-path}pf_din_mono.woff2') format('woff2'),
  url('@{icon-font-path}pf_din_mono.woff') format('woff'),
  url('@{icon-font-path}pf_din_mono.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
